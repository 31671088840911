.SideBarMain {
  margin-top: 10px;

 
  backdrop-filter: blur(4px);
  /* -webkit-backdrop-filter: blur(4px); */
  border-radius: 10px;
  width: 100%;
  padding: 0px 10px 10px 10px;
  height: auto;
  background-image: radial-gradient( circle farthest-corner at 83.7% 4.3%, rgb(173 0 171 / 72%) 0%, rgb(13 60 113) 90% );
  height: 890px;

}
.imgbottom{
  height: 158px;
  margin-left: 30px;
  margin-top: -13px;
}

.profileMain {
  padding-top: 18px;
  text-align: center;
}
.profileMain img {
  width: 100px;
  margin-bottom: 10px;
}
.profileMain h6 {
  font-family: "sans-serif";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 0;
}
.profileMain span {
  font-family: "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
}

.border_image {
  display: block;
  width: 100% !important;
  margin-top: 20px;
}

.dashboardItem {
  background: rgba(255, 255, 255, 0.17);
  backdrop-filter: blur(17px);
  border-radius: 11px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  padding-left: 46px;
  cursor: pointer;
}

.dashboardItem img {
  width: 33px;
  margin-right: 10px;
}
.dashboardItem span {
  font-family: "sans-serif";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}

.MainMenu {
  display: flex;
  align-items: center;
  padding-left: 46px;
  font-family: "sans-serif";
  font-weight: normal;
  font-size: 18px;
  color: #fff !important;
  margin-bottom: 17px;
}
.MainMenu:hover {
  color: #fff !important;
}
.MainMenu img:first-child {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.MainMenu img:last-child {
  margin-left: auto;
  margin-right: 10px;
  width: 17px;
  padding-top: 5px;
}

.Sidebar_submenu {
  padding-left: 77px;
  margin-top: 5px;
}
.Sidebar_submenu a {
  font-family: "sans-serif";
  font-weight: normal;
  font-size: 16px;
  color: #fff !important;
  padding-top: 0px;
}

.logoutIcon {
  display: flex;
  align-items: center;
  padding-left: 46px;
  font-family: "sans-serif";
  font-weight: normal;
  font-size: 18px;
  color: #fff !important;
  margin-bottom: 17px;
  cursor: pointer;
  margin-top: 25px;
}

.logoutIcon img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.SideBottomMain {
  margin-top: 30px;
  margin-bottom: 10px;
}
